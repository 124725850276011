<template>
  <div class="exercise-list-page w-100">
    <basic-subheader title="">
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter"
          no-caret
          right
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form class="mw-400">
            <b-container class="p-0">
              <b-row>
                <b-col>
                  <basic-input
                    label="Tiêu đề"
                    placeholder="Nhập Tiêu đề "
                    name="codeParams"
                    :value.sync="search.title"
                  ></basic-input>
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.stop="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
        <b-button
          v-if="isWritePermission"
          class="btn btn-success ml-2"
          type="button"
          @click="handleCreate"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Tạo bài viết
        </b-button>
      </template>
    </basic-subheader>
    <Wrapper>
      <b-overlay :show="loading">
        <template-table
          :column="headers"
          :data="data"
          :paging="paging"
          :tableAction="false"
          :selectAction="false"
          :searchAction="false"
          @search="searchRequest"
          @reset="resetRequest"
        >
          <template v-slot:body="{ item }">
            <td>
              <img
                :src="item.imageUrl.url ? item.imageUrl.url : placeholder"
                alt=""
                class="img-article"
                onerror="javascript:this.src='/media/placeholder/placeholder-image.png'"
              />
            </td>
            <td>{{ item.title }}</td>
            <td>{{ item.learningPostModule.name }}</td>
            <td>{{ formatDate(item.createDatetime) }}</td>
            <td>{{ item.link && item.link != 'null' ? item.link : '' }}</td>
            <td>
              <div>
                <div class="status" :class="getStatus(item.status)">
                  {{ getStatus(item.status) }}
                </div>
              </div>
            </td>
            <td>
              <a
                v-if="isWritePermission"
                class="btn btn-icon btn-sm"
                @click.stop="handleUpdate(item)"
                style="background-color: #e6f2f8; border: none; color: #407bff"
                title="Chỉnh sửa"
              >
                <span class="menu-icon svg-icon svg-icon-sm icon-edit">
                  <inline-svg
                    class="svg-icon"
                    src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                  />
                </span>
              </a>
            </td>
          </template>
        </template-table>
      </b-overlay>
      <update-article :id.sync="id" @loadData="loadData" :popupType="type" />
    </Wrapper>
  </div>
</template>

<script>
export default {
  name: 'SystemParameter',
  components: { 'update-article': () => import('./components/Modal') },
  data() {
    return {
      loading: false,
      isExpandedNew: true,
      isExpandedOld: true,
      search: {
        key: null,
        value: null,
      },
      filter: {
        sortBy: null,
        sortDesc: null,
      },
      expanded: [],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },

      headers: [
        { label: 'Hình ảnh', key: 'imageUrl' },
        {
          label: 'Tiêu đề bài viết',
          key: 'title',
          sortable: false,
        },
        { label: 'Chủ đề', key: 'learningPostModule', sortable: false },
        { label: 'Ngày đăng', key: 'createDatetime', sortable: false },
        { label: 'Đường dẫn', key: 'link', sortable: false },
        { label: 'Trạng thái', key: 'status', sortable: false },
        { label: '', key: '', sortable: false },
      ],
      data: [],
      detail: {},
      options: {},
      id: null,
      type: null,
    };
  },
  watch: {
    paging: {
      handler() {
        this.loadData();
      },
      deep: true,
    },

    options: {
      handler(newVal) {
        let { sortBy, sortDesc } = newVal;
        let [order] = sortDesc;
        let [sort] = sortBy;
        this.filter.sortBy = sort;

        this.filter.sortDesc = order ? 'desc' : 'asc';
      },
      deep: true,
    },
  },
  computed: {
    searchParams() {
      return {
        title: this.search.title,
        page: this.paging.page,
        size: this.paging.pageSize,
      };
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    placeholder() {
      return require('../../../../public/media/placeholder/placeholder-image.png');
    },
    searchRequest() {
      this.loadData();
      document.body.click();
    },
    resetRequest() {
      this.search.title = null;
      this.$nextTick(() => {
        this.$validator.reset();
      });
      this.loadData();
      document.body.click();
    },
    async loadData() {
      this.loading = true;
      try {
        const { data, meta } = await this.$api.get('LearningPost', {
          params: this.searchParams,
        });
        this.data = data || [];
        this.paging.total = meta.total;
      } catch (error) {
        this.$toastr.e(error, 'ERROR');
      } finally {
        this.loading = false;
      }
    },
    handleCreate() {
      this.type = 'create';
      this.$bvModal.show('article-modal');
    },
    handleUpdate(payload) {
      this.id = payload.id;
      this.type = 'edit';
      this.$bvModal.show('article-modal');
    },

    expandAll() {
      this.isExpandedNew = !this.isExpandedOld;
      // Filter vue-components
      Object.keys(this.$refs)
        .filter((e) => this.$refs[e]?.$el)
        .forEach((k) => {
          // Check if element has already open
          const status = this.$refs[k].$attrs['data-open'];
          if (status != this.isExpandedNew) {
            this.$refs[k].$el.click();
          }
          return;
        });
      this.isExpandedOld = this.isExpandedNew;
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'inactive';
        case 1:
          return 'active';
        default:
          return 'draft';
      }
    },
    formatDate(date) {
      const isValid = this.$moment(date, 'MM/DD/YYYY HH:mm:ss').isValid();
      if (!isValid) return null;
      return this.$moment(date).add(7, 'hours').format('DD/MM/YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-edit {
  background-color: #e6f2f8;
  border: none;
  color: #407bff;
}
.mw-400 {
  min-width: 400px;
}
.img-article {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  width: 96px;
  text-align: center;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }

  &.draft {
    background: #888c9f;
  }
}
</style>
